<script>
import {isEmpty} from 'element-ui/src/utils/util';
import {getOferta,getDetalleOferta} from '@/routes/api/views/flujo';
import ElOfertaDownSolicitudDefault from "./defaults/partsSolicitud/OfertaDownSolicitudDefault";
import ElOfertaUpSolicitudDefault from "./defaults/partsSolicitud/OfertaUpSolicitudDefault";
import ElOfertaUpDescuentosDefault from "./defaults/partsDescuentos/OfertaUpDescuentosDefault";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import {mapGetters} from "vuex";

export default {
    name: 'PzBaseOfertas',
    components: {},
    mixins: [baseFormFlujo],
    provide: function () {
        return {
            ofertas: this
        }
    },
    data() {
        return {
            reference: 'otras_ofertas',
            elOfertaView: '',
            best: '',
            montos_array: '',
            cuotas_array: '',
            down: false,
            mycomponents: [ElOfertaUpSolicitudDefault.name, ElOfertaDownSolicitudDefault.name, ElOfertaUpDescuentosDefault.name],
            detalle: '',
            viewDetalle:false,
            viewInfo:false
        }
    },
    computed: {
      ...mapGetters([ "form_product"]),
    },
     created() {
        this.$store.dispatch('loader/up', {trigger: this.$options.name})
        if (this.producto.needsDocuments) {
            this.getOfertaView('descuentos');
        } else {
            this.getOfertaView('solicitud');
        }

        this.obtenerOferta();

    },

    methods: {
        getOfertaView(tipe) {
            this.elOfertaView = tipe + '-' + this.extraData.ofertaView;
        },
        async obtenerOferta(){
            await getOferta(this.loan, {
                withAdditionals: this.extraData.withAdditionals,
            }).then((res) => {
                this.best = res.data;
                if (!isEmpty(res.data.adicionales)) {
                    this.viewInfo=this.form_product.route_params.view_info
                    this.cuotas_array = res.data.adicionales.cuotas;
                    this.montos_array = res.data.adicionales.montos;
                    this.down = this.extraData.withAdditionals;
                }
                this.viewDetalleOferta();
            }).catch(() => {
                this.$router.push({name: 'Rejected'});
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
        async viewDetalleOferta(){
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            await getDetalleOferta( {
                loan: this.loan,
                monto:  this.best.monto ,
            }).then((res) => {
                if (!isEmpty(res.data.detalle)) {
                    this.detalle = res.data.detalle;
                    this.viewDetalle = true;
                }
            }).catch(() => {
                this.$router.push({name: 'Rejected'});
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        }

    }
}
</script>


