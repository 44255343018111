import PzBaseForm from "@/commons/forms/PzBaseForm";
import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";
import PzInputCoint from "@/commons/forms/Fields/PzInputCoin";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzCaptchaButton from "@/commons/forms/Buttons/PzCaptchaButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import PzButton from "@/commons/forms/Buttons/PzButton";
import PzLanguage from "@/lang/PzLanguage";
import PzTitle from "@/commons/titles/PzTitle";
import PzLoaderMixins from "@/commons/PzLoaderMixins";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzDocusingForm from "@/commons/forms/DigitalSings/Docusign/PzDocusingForm";
import PzDocusing from "@/commons/forms/DigitalSings/Docusign/PzDocusign";
import PzYousingForm from "@/commons/forms/DigitalSings/Yousign/PzYousignForm";
import PzYousingV3Form from "@/commons/forms/DigitalSings/YousignV3/PzYousignForm";
import PzYousing from "@/commons/forms/DigitalSings/Yousign/PzYousign";
import PzYousingV3 from "@/commons/forms/DigitalSings/YousignV3/PzYousign";
import PzUpload from "@/commons/forms/Fields/PzUpload";
import PzNaat from "@/commons/forms/DigitalSings/Naat/PzNaat";
import PzNaatForm from "@/commons/forms/DigitalSings/Naat/PzNaatForm";
import PzUnnax from "@/commons/forms/PayIn/PzUnnax";
import PzFad from "@/commons/forms/DigitalSings/Fad/PzFad";
import PzFadForm from "@/commons/forms/DigitalSings/Fad/PzFadForm";
import PzFadSdk from "@/commons/forms/DigitalSings/Fad2/PzFadSdk";
import PzFadSdkForm from "@/commons/forms/DigitalSings/Fad2/PzFadSdkForm";
export default [
  PzUnnax,
  PzNaatForm,
  PzNaat,
  PzUpload,
  PzDocusing,
  PzYousing,
  PzYousingV3,
  PzDocusingForm,
  PzYousingForm,
  PzYousingV3Form,
  PzIdentityDocument,
  PzLoaderMixins,
  PzButton,
  PzBackButton,
  PzForwardButton,
  PzCaptchaButton,
  PzLanguage,
  PzBaseForm,
  PzTitle,
  PzInputCoint,
  PzTermsAndConditions,
    PzFad,
    PzFadForm,
    PzFadSdk,
    PzFadSdkForm,
];
