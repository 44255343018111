<template>
  <div>
    <div style="width: 100%" id="iframe-container"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PzYousignV3",
  props: {
    route: {
      required: true,
      default: null,
      type: [String, URL],
    },
    redirect: {
      required: true,
      default: "Prestamos",
      type: [String],
    },
  },
  data() {
    return {
      yousignInstance: null,
    };
  },
  computed: {
    ...mapGetters(["alias"]),
  },
  mounted() {
    this.loadYousignScript()
        .then(() => {
          this.initializeYousign();
        })
        .catch((error) => {
          console.error("Failed to load Yousign script:", error);
        });
  },
  beforeDestroy() {
    if (this.yousignInstance) {
      this.yousignInstance.removeMessageListener();
    }
  },
  methods: {
    loadYousignScript() {
      return new Promise((resolve, reject) => {
        if (document.getElementById("yousign-sdk")) {
          // Script is already loaded
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.id = "yousign-sdk";
        script.src = "https://cdn.yousign.tech/iframe-sdk-1.6.0.min.js";
        script.integrity =
            "sha384-/7MD1voOOzWVz7FmgeMwmmd1DO85Mo0PkkxdYd9j2wDGzGDGRG/phgnL0c9Xyy52";
        script.crossOrigin = "anonymous";
        script.onload = () => resolve();
        script.onerror = (e) => reject(e);

        document.head.appendChild(script);
      });
    },
    initializeYousign() {
      const Yousign = window.Yousign;
      if (!Yousign) {
        console.error("Yousign SDK not available");
        return;
      }

      this.yousignInstance = new Yousign({
        signatureLink: this.route,
        iframeContainerId: "iframe-container",
        isSandbox: true, // Set to false in production
        iFrameAttributes: {
          referrerPolicy: "origin-when-cross-origin",
        },
      });

      this.yousignInstance.onStarted(() => {
        console.log("Signer has opened the signature");
      });

      this.yousignInstance.onSuccess(() => {
        console.log("Signer has successfully signed the document");
      });

      this.yousignInstance.onError((data) => {
        console.error("Signer encountered an error when signing:", data);
      });

      this.yousignInstance.onSignatureDone(() => {
        console.log("Signature completed");
        this.$router.push({ name: this.redirect });
      });
    },
  },
};
</script>
<style>
#yousign-iframe {
  width: 100%;
  border: none;
  height: 600px;
}
</style>
