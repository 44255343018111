<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.personas_expuestas') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.is_pep"
            :label="$t('forms_flujo.form1_persona.UY.is_pep')"
            :error="errors.get('info_extra.is_pep')"
          >
            <el-switch
                v-model="formulario1.info_extra.is_pep"
                active-text="SI"
                inactive-text="NO"
            />

              <el-col
                      class="pz-font-size-sm"
                      style="transform: translateY(-18px)"
              >
                  <el-popover
                  placement="top-start"
                  title="Personas Políticamente Expuestas"
                  width="400"
                  trigger="hover"
                  :content="$t('forms_flujo.form1_persona.UY.is_pep_info')">
                  <i slot="reference" class="el-icon-info pz-font-size-bs  pz-color-secondary"></i>
                  </el-popover>
              </el-col>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
              prop="info_extra.relacion_pep"
              :label="$t('forms_flujo.form1_persona.UY.relacion_pep')"
              :error="errors.get('info_extra.relacion_pep')"
          >
            <el-switch
                v-model="formulario1.info_extra.relacion_pep"
                active-text="SI"
                inactive-text="NO"
            />
              <el-col
                      class="pz-font-size-sm"

              >
                  <el-popover
                          placement="top-start"
                          title="RELACIÓN"
                          width="400"
                          trigger="hover"
                          :content="$t('forms_flujo.form1_persona.UY.relacion_pep_info')">
                      <i slot="reference" class="el-icon-info pz-font-size-bs  pz-color-secondary" />
                  </el-popover>
              </el-col>

          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="info_extra.cargo_pep"
              :label="$t('forms_flujo.form1_persona.UY.cargo_pep')"
              :error="errors.get('info_extra.cargo_pep')"
          >
            <el-input
                ref="info_extra.cargo_pep"
                v-model="formulario1.info_extra.cargo_pep"
                :type="'text'"
                :placeholder="$t('forms_flujo.form1_persona.UY.cargo_pep')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import 'element-ui/lib/theme-chalk/display.css'
    import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";

    export default {
        name: 'PzPersonForm3Hipotecalo',
        components: { PzFormFlujo},
        mixins: [PzBaseForm1],
        data() {
            return {
                reference: 'formulario1',
                formulario1: {
                    info_extra:{
                        cargo_pep: '',
                        is_pep: '',
                        relacion_pep: ''
                    },
                },

                rules_formulario1: {

                },
            };
        },

    }
</script>

