<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.datos_personales') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
                ref="nombres"
                v-model="formulario1.nombres"
                :type="'text'"
                :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellido_paterno"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellido_paterno"
              v-model="formulario1.apellido_paterno"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <pz-identity-document
                  v-model="formulario1.identity"
                  prop="identity"
                  type="person"
                  :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
                  :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
              />
          </el-col>
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="doc_lugar_emision"
              :label="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
          >
            <el-select
                ref="doc_lugar_emision"
                v-model="formulario1.doc_lugar_emision"
                clearable
                filterable
                :placeholder="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
            >
              <el-option
                  v-for="item in paises"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.id"
                  :placeholder="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
        <pz-direction
            v-model="formulario1.direccion"
        />
      <el-row :gutter="20">
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="email"
                  :label="$t('forms_flujo.form1_persona.UY.email')"
                  :error="errors.get('email')"
              >
                  <el-input
                      ref="email"
                      v-model="formulario1.email"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.UY.email')"
                  />
              </el-form-item>
          </el-col>
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="telefono"
                  :label="$t('forms_flujo.form1_persona.UY.telefono')"
                  :error="errors.get('telefono')"
              >
                  <el-input
                      ref="telefono"
                      v-model="formulario1.telefono"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
                  />
              </el-form-item>
          </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
            v-for="(condition) in producto.conditions"
            :key="condition.id"
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
            class="row justify-content-between"
        >
          <pz-terms-and-conditions
              v-model="formulario1.conditions[condition.slug]"
              :prop="'condition_'+condition.slug"
              :name="condition.nombre"
          >
            <div v-html="condition.contenido" />
          </pz-terms-and-conditions>
        </el-col>
      </el-row>
        <el-row :gutter="20">
            <el-col
                :span="24"
                class="text-center pz-large-margin"
            >
                <div class="pz-title">
                    {{ $t('forms_flujo.form1_persona.UY.datos_inmueble') }}
                </div>
            </el-col>
        </el-row>
      <el-row v-for="(inmueble, index) in formulario1.info_extra" >
        <el-row>
          <div class="pz-title">
            {{index + 1}}
          </div>
          <div style="margin-bottom: 10px;"></div>
        </el-row>

        <el-row :gutter="20">
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  :prop="'info_extra.' + index + '.padron'"
                  :label="$t('forms_flujo.form1_persona.UY.padron')"
                  :error="errors.get('info_extra.padron')"
              >
                  <el-input
                      :ref="'info_extra.' + index + '.padron'"
                      v-model="inmueble.padron"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.UY.padron')"
                  />
              </el-form-item>
          </el-col>
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              :prop="'info_extra.' + index + '.departamento_inmueble'"
              :label="$t('forms_flujo.form1_persona.UY.departamento')"
              :error="errors.get('info_extra.departamento')"
          >
            <el-select
                :ref="'info_extra.' + index + '.departamento_inmueble'"
                v-model="inmueble.departamento_inmueble"
                clearable
                :placeholder="$t('forms_flujo.form1_persona.UY.departamento')"
            >
              <el-option
                  v-for="item in departamentos"
                  :key="item.id"
                  :label="item.departamento"
                  :value="item.id"
                  :placeholder="$t('forms_flujo.form1_persona.UY.departamento')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                :prop="'info_extra.' + index + '.localidad'"
                :label="$t('forms_flujo.form1_persona.UY.localidad')"
                :error="errors.get('info_extra.localidad')"
            >
                <el-input
                        :ref="'info_extra.' + index + '.localidad'"
                        v-model="inmueble.localidad"
                        :type="'text'"
                        :placeholder="$t('forms_flujo.form1_persona.UY.localidad')"
                />
            </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  :prop="'info_extra.' + index + '.valor_estimado'"
                  :label="$t('forms_flujo.form1_persona.UY.valor_estimado')"
                  :error="errors.get('info_extra.valor_estimado')"
          >
            <el-input
                    :ref="'info_extra.' + index + '.valor_estimado'"
                    v-model="inmueble.valor_estimado"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.valor_estimado')"
            />
          </el-form-item>
        </el-col>
      </el-row>
        <el-row v-if="index > 0">
          <el-button style="float: right;" @click="removeInmueble(index)" type="danger">
            Eliminar
          </el-button>
        </el-row>
      </el-row>

      <el-button  @click="addInmueble">Nuevo inmueble</el-button>

    </el-form>
  </pz-form-flujo>
</template>
<script>

    import {required, type} from "@/commons/utils/Rules";
    import {getDepartamentos, getPaises} from "@/routes/api/resources";
    import 'element-ui/lib/theme-chalk/display.css'
    import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
    import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
    import PzDirection from "@/commons/forms/Fields/PzDirection";
    import i18n from "@/lang/lang_config";
    import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";


    export default {
        name: 'PzPersonForm1Hipotecalo',
        components: { PzCheckForm, PzFormFlujo, PzIdentityDocument,PzDirection,PzTermsAndConditions},
        mixins: [PzBaseForm1],
        data() {
            return {
                reference: 'formulario1',
                paises: [],
                departamentos: [],
                formulario1: {
                    nombres: '',
                    apellido_paterno: '',
                    doc_lugar_emision:'',
                    direccion:{},
                    identity:{},
                    email:'',
                    telefono:'',
                    info_extra: [
                        {
                        padron: '',
                        departamento_inmueble: '',
                        localidad: '',
                        valor_estimado: ''
                        }
                    ]
                },

                rules_formulario1: {
                    nombres: [required('')],
                    apellido_paterno: [required('')],
                    'identity.document': [required('')],
                    'identity.number': [required('')],
                    doc_lugar_emision: [required('')],
                    email: [
                        required(''),
                        type({field: '', type: 'email'})
                    ],
                  info_extra: {

                  }
                },
            };
        },
        created() {
            this.fetchData();
          this.buildInfoExtraRules();
        },

        methods: {
            fetchData() {
                this.$store.dispatch('loader/up', {trigger: this.$options.name});
                let departamentos = getDepartamentos().then((res) => {
                    this.departamentos = res.data;
                }).catch(() => {
                    this.departamentos = [];
                });
                let paises = getPaises().then((res) => {
                    this.paises = res.data;
                }).catch(() => {
                    this.paises = [];
                });
                return Promise.all([departamentos,paises]).then(() => {
                }).finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name})});
            },
          buildInfoExtraRules() {
            // Dynamically create validation rules for each inmueble
            this.rules_formulario1.info_extra = this.formulario1.info_extra.reduce((rules, inmueble, index) => {
              rules[`info_extra.${index}.padron`] = [required(i18n.tc('labels.padron'))];
              rules[`info_extra.${index}.departamento_inmueble`] = [required(i18n.tc('labels.departamento_inmueble'))];
             // rules[`info_extra.${index}.localidad`] = [required(i18n.tc('labels.localidad'))];
             // rules[`info_extra.${index}.valor_estimado`] = [required(i18n.tc('labels.valor_estimado'))];
              return rules;
            }, {});
          },
          addInmueble() {
            this.formulario1.info_extra.push({
              padron: '',
              departamento_inmueble: '',
              localidad: '',
              valor_estimado: ''
            });
            this.buildInfoExtraRules();
          },
          removeInmueble(index) {
            if (index > 0) {
              this.formulario1.info_extra.splice(index, 1);
              this.buildInfoExtraRules();
            }
          }
        },



    }
</script>

