export function lazyLoad(view) {
  return () => import(`@/views/inversores/${view}.vue`);
}

export default [
  {
    path: "create",
    component: lazyLoad("Create"),
    name: "CreateInversores",
    hidden: true,
    props: true,
    meta: { title: "Datos Inversores", hidden: true },
  },
  {
    path: "finalizado",
    component: lazyLoad("Final"),
    name: "FinInversor",
    hidden: true,
    props: true,
    meta: { title: "Datos Inversores", hidden: true },
  },
];
