<template>
  <pz-form-flujo
    :forward="handleSend"
  >
    <span slot="form_title">
      {{ $t('commons.youSing.title') }}
    </span>
    <span slot="form_subtitle">
      {{ $t('commons.youSing.sub_title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="formulario"
      status-icon
      :rules="rules_formulario_validarTelefono"
      @validate="handleValidate"
    >
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
      >
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="identity"
            :error="errors.get('numero_documento')"
          >
            <pz-identity-document
              v-model="formulario.identity"
              prop="identity"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
      >
        <el-col
          :xl="{span: 12}"
          identity
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        />
      </el-row>
    </el-form>
    <el-col
      slot="buttons"
      :span="24"
      class="center-block text-center"
    >
<!--      <pz-captcha-button-->
<!--        v-model="formulario['captcha']"-->
<!--        :forward="handleSend"-->
<!--      />-->
      <pz-forward-button :forward="handleSend"></pz-forward-button>
    </el-col>
  </pz-form-flujo>
</template>

<script>
import {required} from '@/commons/utils/Rules';
import PzBaseForm from "@/commons/forms/PzBaseForm";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";

export default {
    name: "PzYousignForm",
    components: {PzForwardButton, PzIdentityDocument, PzFormFlujo},
    mixins: [PzBaseForm],
    props: {
        loan: {
            type: [Number, String],
            required: true
        }
    },
    data() {
        return {
            reference: 'formularioYouSing',
            formulario: {
                numero_documento: {},
                identity: {},
                loan: this.loan
            },
            rules_formulario_validarTelefono: {
                'identity.document': [required('')],
                'identity.number': [required('')],
            }
        }
    },
    methods: {
        required,
        setNumeroDoc() {
            this.formulario.numero_documento = {[this.formulario.identity.document]: this.formulario.identity.number};
        },
        handleResend() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.setNumeroDoc();
            let isvalid = this.handleSave();
            if (isvalid) {
                this.$emit('input', this.formulario);
                this.$emit('resend');
            }
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        },
        handleSend() {
            console.log('handle send');
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.setNumeroDoc();
            let isvalid = this.handleSave();
            if (isvalid) {
                this.$emit('input', this.formulario);
                this.$emit('send');
            }
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        }
    }
}
</script>

