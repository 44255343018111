<template>
  <pz-form-flujo
    :forward="handleSend"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.datos_inversores') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
                ref="nombres"
                v-model="formulario1.nombres"
                :type="'text'"
                :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellido_paterno"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellido_paterno"
              v-model="formulario1.apellido_paterno"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <pz-identity-document
                  v-model="formulario1.identity"
                  prop="identity"
                  type="all"
                  :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
                  :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
              />
          </el-col>
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="doc_lugar_emision"
              :label="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
          >
            <el-select
                ref="doc_lugar_emision"
                v-model="formulario1.doc_lugar_emision"
                clearable
                filterable
                :placeholder="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
            >
              <el-option
                  v-for="item in paises"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.id"
                  :placeholder="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
        <pz-direction
            v-model="formulario1.direccion"
        />
      <el-row :gutter="20">
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="email"
                  :label="$t('forms_flujo.form1_persona.UY.email')"
                  :error="errors.get('email')"
              >
                  <el-input
                      ref="email"
                      v-model="formulario1.email"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.UY.email')"
                  />
              </el-form-item>
          </el-col>
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="telefono"
                  :label="$t('forms_flujo.form1_persona.UY.telefono')"
                  :error="errors.get('telefono')"
              >
                  <el-input
                      ref="telefono"
                      v-model="formulario1.telefono"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
                  />
              </el-form-item>
          </el-col>
      </el-row>
      <el-row :gutter="20">
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="data_extra.banco_deposito"
                  :label="$t('forms_flujo.form1_persona.UY.banco_deposito')"
                  :error="errors.get('data_extra.banco_deposito')"
              >
                  <el-select
                      ref="data_extra.banco_deposito"
                      v-model="formulario1.data_extra.banco_deposito"
                      clearable
                      :placeholder="$t('forms_flujo.form1_persona.UY.banco_deposito')"
                  >
                      <el-option
                          v-for="item in bancos"
                          :key="item.id"
                          :label="item.short_name"
                          :value="item.id"
                      />
                  </el-select>
              </el-form-item>
          </el-col>
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="data_extra.sucursal_banco"
              :label="$t('forms_flujo.form1_persona.UY.sucursal_banco')"
              :error="errors.get('data_extra.sucursal_banco')"
          >
              <el-input
                  ref="data_extra.sucursal_banco"
                  v-model="formulario1.data_extra.sucursal_banco"
                  :type="'text'"
                  :placeholder="$t('forms_flujo.form1_persona.UY.sucursal_banco')"
              />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="data_extra.cuenta_deposito"
                :label="$t('forms_flujo.form1_persona.UY.cuenta_deposito')"
                :error="errors.get('data_extra.cuenta_deposito')"
            >
                <el-input
                    ref="data_extra.cuenta_deposito"
                    v-model="formulario1.data_extra.cuenta_deposito"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.cuenta_deposito')"
                />
            </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

    import {required, type} from "@/commons/utils/Rules";
    import {getBancos, getPaises} from "@/routes/api/resources";
    import {setInversores} from "@/routes/api/views/flujo";
    import 'element-ui/lib/theme-chalk/display.css'
    import PzBaseForm from "@/commons/forms/PzBaseForm";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
    import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
    import PzDirection from "@/commons/forms/Fields/PzDirection";
    import {isEmpty, merge} from "@/commons/utils/functions";

    export default {
        name: 'PzPersonForm1Inversor',
        components: { PzCheckForm, PzFormFlujo, PzIdentityDocument,PzDirection},
        mixins: [PzBaseForm],
        data() {
            return {
                reference: 'formulario1',
                paises: [],
                bancos: [],
                formulario1: {
                    nombres: '',
                    apellido_paterno: '',
                    doc_lugar_emision:'',
                    direccion:{},
                    identity:{},
                    email:'',
                    telefono:'',                    
                    data_extra: {
                        sucursal_banco: '',
                        banco_deposito: '',
                        cuenta_deposito: '',
                    }
                },

                rules_formulario1: {
                    nombres: [required('')],
                    apellido_paterno: [required('')],
                    'identity.document': [required('')],
                    'identity.number': [required('')],
                    doc_lugar_emision: [required('')],
                    telefono: [required('')],
                 
                    email: [
                         required(''),
                        type({field: '', type: 'email'})
                    ],
                    data_extra: {
                        sucursal_banco:[required('')],
                        banco_deposito: [required('')],
                        cuenta_deposito: [required('')],
                    }
                },
            };
        },
       
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.$store.dispatch('loader/up', {trigger: this.$options.name});
                let bancos = getBancos().then((res) => {
                    this.bancos = res.data;
                }).catch(() => {
                    this.bancos = [];
                });
                let paises = getPaises().then((res) => {
                    this.paises = res.data;
                }).catch(() => {
                    this.paises = [];
                });
                return Promise.all([bancos,paises]).then(() => {
                }).finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name})});
            },
            merging(form, params) {
                if (!Object.prototype.hasOwnProperty.call(form, "numero_documento")) {
                    form['numero_documento'] = {};
                }
                if (Object.prototype.hasOwnProperty.call(form, "identity") && !isEmpty(form.identity)) {
                    form['numero_documento'][form.identity.document] = form.identity.number;
                }
                if (!isEmpty(params)) return merge(form, params); else return form;
            },
            
            sendMessages(title, message) {
            this.$confirm(message,
                title,
                {
                showClose: false,
                showCancelButton: false,
                confirmButtonText: this.$t('views.garante.alert.btn_aceptar'),
                confirmButtonColor: "#fcb326",
                type: 'alert',
                roundButton: true,
                customClass: 'capitas',
                closeOnClickModal: false,
                closeOnPressEscape: false
                });
            },
            handleSend() {
                this.$refs[this.reference].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('loader/up', {trigger: this.$options.name});
                        this.formulario1 =  this.merging(this.formulario1, {})
                    
                        setInversores(this.formulario1)
                        .then(() => {
                            this.$router.push({name: 'FinInversor'});
                        })
                        .catch((error) => {
                            this.sendMessages(this.$t('views.not_available.title.no_services'), this.$t('views.not_available.no_services'));
                            })
                        .finally(() => {
                            this.$store.dispatch('loader/down', {trigger: this.$options.name});
                           
                        });
                    }else {
                        this.valid = false;
                        return false;
                    }
            });
        }
        },
    }
</script>

