<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.datos_conyugue') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombre_conyuge"
            :label="$t('forms_flujo.form1_persona.UY.nombre_conyuge')"
            :error="errors.get('nombre_conyuge')"
          >
            <el-input
                ref="nombre_conyuge"
                v-model="formulario1.nombre_conyuge"
                :type="'text'"
                :placeholder="$t('forms_flujo.form1_persona.UY.nombre_conyuge')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
                  prop="nacionalidad_conyuge"
                  :label="$t('forms_flujo.form1_persona.SP.nacionalidad')"
          >
            <el-select
                    ref="nacionalidad_conyuge"
                    v-model="formulario1.nacionalidad_conyuge"
                    clearable
                    filterable
                    :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
            >
              <el-option
                      v-for="item in paises"
                      :key="item.id"
                      :label="item.nacionalidad"
                      :value="item.id"
                      :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="fecha_nacimiento_conyuge"
              :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
              :error="errors.get('fecha_nacimiento_conyuge')"
          >
            <el-date-picker
                ref="fecha_nacimiento_conyuge"
                v-model="formulario1.fecha_nacimiento_conyuge"
                type="date"
                format="dd/MM/yyyy"
                value-format="dd/MM/yyyy"
                :picker-options="pickerOptions1"
                :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="lugar_nacimiento_conyuge"
                  :label="$t('forms_flujo.form1_persona.SP.lugar_nacimiento')"
                  :error="errors.get('lugar_nacimiento_conyuge')"
          >
            <el-input
                    ref="lugar_nacimiento_conyuge"
                    v-model="formulario1.lugar_nacimiento_conyuge"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.SP.lugar_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">

        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="bienes_separados"
                  :error="errors.get('bienes_separados')"
                  :label="$t('forms_flujo.form1_persona.UY.con_separacion_bienes')"
          >
            <el-switch
                    v-model="formulario1.bienes_separados"
                    active-text="SI"
                    inactive-text="NO"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <pz-identity-document
                  v-model="formulario1.identity_conyugue"
                  prop="identity_conyugue"
                  type="person"
                  :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
                  :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="doc_vencimiento_conyugue"
            :label="$t('forms_flujo.form1_persona.UY.doc_vencimiento')"
            :error="errors.get('doc_vencimiento_conyugue')"
          >
            <el-date-picker
                    ref="doc_vencimiento_conyugue"
                    v-model="formulario1.doc_vencimiento_conyugue"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="dd/MM/yyyy"
                    :placeholder="$t('forms_flujo.form1_persona.UY.doc_vencimiento')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="doc_lugar_emision_conyugue"
                  :label="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
                  :error="errors.get('doc_lugar_emision_conyugue')"
          >
            <el-input
                    ref="doc_lugar_emision_conyugue"
                    v-model="formulario1.doc_lugar_emision_conyugue"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.doc_lugar_emision')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

    import { getPaises} from "@/routes/api/resources";
    import 'element-ui/lib/theme-chalk/display.css'
    import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";

    export default {
        name: 'PzPersonForm2Hipotecalo',
        components: { PzFormFlujo, PzIdentityDocument},
        mixins: [PzBaseForm1],
        data() {
            return {
                reference: 'formulario1',
                paises: [],
                formulario1: {
                    nombre_conyuge: '',
                    nacionalidad_conyuge: '',
                    fecha_nacimiento_conyuge: '',
                    lugar_nacimiento_conyuge: '',
                    bienes_separados: '',
                    identity_conyugue: {document: 1},
                    doc_vencimiento_conyugue:'',
                    doc_lugar_emision_conyugue:''
                },

                rules_formulario1: {

                },
                pickerOptions1: {
                    disabledDate(time) {
                        return Date.now() < time.getTime();
                    },
                },
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.$store.dispatch('loader/up', {trigger: this.$options.name})
                let paises = getPaises().then((res) => {
                    this.paises = res.data;
                }).catch(() => {
                    this.paises = [];
                });
                return Promise.all([paises]).then(() => {
                }).finally(() => {this.$store.dispatch('loader/down', {trigger: this.$options.name})});
            },
        },


    }
</script>

