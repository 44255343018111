<template>
  <pz-form-flujo
          v-model="formulario2['captcha']"
          :forward="handleSave"
          :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.datos_laborales') }}
    </span>

    <el-form
            slot="formulario"
            :ref="reference"
            label-position="top"
            :model="formulario2"
            status-icon
            :rules="rules_formulario2"
    >
      <label>{{ $t('forms_flujo.form2_persona.UY.actividad_principal') }}</label>
      <hr
              class="pz-dotted"
              style="margin-top: 1px"
      >
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="profesion"
                  :label="$t('forms_flujo.form2_persona.UY.profesion')"
                  :error="errors.get('profesion')"
          >
            <el-select
                    ref="profesion"
                    v-model="formulario2.profesion"
                    clearable
                    :placeholder="$t('forms_flujo.form2_persona.UY.profesion')"
            >
              <el-option
                      v-for="item in data_profesion"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                      :placeholder="$t('forms_flujo.form2_persona.UY.profesion')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="empresa_trabajo"
                  :label="$t('forms_flujo.form2_persona.UY.empresa_trabajo')"
                  :error="errors.get('empresa_trabajo')"
          >
            <el-input
                    ref="empresa_trabajo"
                    v-model="formulario2.empresa_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.empresa_trabajo')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="ramo_trabajo"
                  :label="$t('forms_flujo.form2_persona.UY.ramo')"
                  :error="errors.get('ramo_trabajo')"
          >
            <el-input
                    ref="ramo_trabajo"
                    v-model="formulario2.ramo_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.ramo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="ciudad_trabajo"
                  :label="$t('forms_flujo.form2_persona.UY.ciudad')"
                  :error="errors.get('ciudad_trabajo')"
          >
            <el-input
                    ref="ciudad_trabajo"
                    v-model="formulario2.ciudad_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.ciudad')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="codigo_postal_trabajo"
                  :label="$t('forms_flujo.form2_persona.UY.codigo_postal')"
                  :error="errors.get('codigo_postal_trabajo')"
          >
            <el-input
                    ref="codigo_postal_trabajo"
                    v-model="formulario2.codigo_postal_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.codigo_postal')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="telefono_empresa"
                  :label="$t('forms_flujo.form2_persona.UY.telefono')"
                  :error="errors.get('telefono_empresa')"
          >
            <el-input
                    ref="telefono_empresa"
                    v-model="formulario2.telefono_empresa"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.telefono')"
            />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="cargo_ocupa"
                  :label="$t('forms_flujo.form2_persona.UY.cargo_ocupa')"
                  :error="errors.get('cargo_ocupa')"
          >
            <el-input
                    ref="cargo_ocupa"
                    v-model="formulario2.cargo_ocupa"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.cargo_ocupa')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
        <el-form-item
                prop="fecha_ingreso"
                :label="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
                :error="errors.get('fecha_ingreso')"
        >
          <el-date-picker
                  ref="fecha_ingreso"
                  v-model="formulario2.fecha_ingreso"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="dd/MM/yyyy"
                  :picker-options="pickerOptions1"
                  :placeholder="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
          />
        </el-form-item>
        </el-col>
      </el-row>
        <el-col
                :span="24"
                class="text-center pz-large-margin"
        >
            <div class="pz-subtitle">
                <label>{{ $t('forms_flujo.form2_persona.UY.actividad_secundaria') }}</label>
                <hr
                        class="pz-dotted"
                        style="margin-top: 1px"
                >
            </div>
        </el-col>

      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="laboral_secundaria.empresa_trabajo"
              :label="$t('forms_flujo.form2_persona.UY.empresa_trabajo')"
              :error="errors.get('laboral_secundaria.empresa_trabajo')"
          >
            <el-input
                ref="laboral_secundaria.empresa_trabajo"
                v-model="formulario2.laboral_secundaria.empresa_trabajo"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.empresa_trabajo')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
              prop="laboral_secundaria.ramo_trabajo"
              :label="$t('forms_flujo.form2_persona.UY.ramo')"
              :error="errors.get('laboral_secundaria.ramo_trabajo')"
          >
            <el-input
                ref="laboral_secundaria.ramo_trabajo"
                v-model="formulario2.laboral_secundaria.ramo_trabajo"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.ramo')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
                prop="laboral_secundaria.ciudad_trabajo"
                :label="$t('forms_flujo.form2_persona.UY.ciudad')"
                :error="errors.get('laboral_secundaria.ciudad_trabajo')"
          >
            <el-input
                  ref="laboral_secundaria.ciudad_trabajo"
                  v-model="formulario2.laboral_secundaria.ciudad_trabajo"
                  :type="'text'"
                  :placeholder="$t('forms_flujo.form2_persona.UY.ciudad')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                prop="laboral_secundaria.codigo_postal_trabajo"
                :label="$t('forms_flujo.form2_persona.UY.codigo_postal')"
                :error="errors.get('laboral_secundaria.codigo_postal_trabajo')"
          >
            <el-input
                ref="laboral_secundaria.codigo_postal_trabajo"
                v-model="formulario2.laboral_secundaria.codigo_postal_trabajo"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.codigo_postal')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="laboral_secundaria.telefono_empresa"
              :label="$t('forms_flujo.form2_persona.UY.telefono')"
              :error="errors.get('laboral_secundaria.telefono_empresa')"
          >
            <el-input
                ref="laboral_secundaria.telefono"
                v-model="formulario2.laboral_secundaria.telefono_empresa"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.telefono')"
            />
          </el-form-item>
        </el-col>
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="laboral_secundaria.cargo_ocupa"
              :label="$t('forms_flujo.form2_persona.UY.cargo_ocupa')"
              :error="errors.get('laboral_secundaria.cargo_ocupa')"
          >
            <el-input
                ref="laboral_secundaria.cargo_ocupa"
                v-model="formulario2.laboral_secundaria.cargo_ocupa"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.cargo_ocupa')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
          <el-col
                  :xl="{span: 12}"
                  :lg="{span: 12}"
                  :md="{span: 12}"
                  :sm="{span: 24}"
                  :xs="{span: 24}"
          >
        <el-form-item
            prop="laboral_secundaria.fecha_ingreso"
            :label="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            :error="errors.get('laboral_secundaria.fecha_ingreso')"
        >
          <el-date-picker
              ref="laboral_secundaria.fecha_ingreso"
              v-model="formulario2.laboral_secundaria.fecha_ingreso"
              type="date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
          />
        </el-form-item>
          </el-col>
      </el-row>


    </el-form>
  </pz-form-flujo>
</template>
<script>
    import {required} from "@/commons/utils/Rules";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import d_profesion from "@/assets/data/profesion";

    export default {
        name: 'PzPersonFormExtHipotecalo',
        components: {PzFormFlujo},
        mixins: [baseform2],
        data() {

            return {
                data_profesion: d_profesion,
                isjubilado:false,
                formulario2: {
                    profesion: '',
                    empresa_trabajo: '',
                    ramo_trabajo: '',
                    ciudad_trabajo: '',
                    codigo_postal_trabajo: '',
                    telefono_empresa: '',
                    cargo_ocupa: '',
                    fecha_ingreso: '',
                    laboral_secundaria:{
                        empresa_trabajo: '',
                        ramo_trabajo: '',
                        ciudad_trabajo: '',
                        codigo_postal_trabajo: '',
                        telefono_empresa: '',
                        cargo_ocupa: '',
                        fecha_ingreso: '',
                    }
                },

                disable: true,
                rules_formulario2:
                    {

                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            };
        },

    }
</script>

