<template>
  <pz-form-flujo
          v-model="formulario2['captcha']"
          :forward="handleSave"
          :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.procedencia_fondos') }}
    </span>

    <el-form
            slot="formulario"
            :ref="reference"
            label-position="top"
            :model="formulario2"
            status-icon
            :rules="rules_formulario2"
    >
      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="procedencia"
              :label="$t('forms_flujo.form2_persona.UY.procedencia')"
              :error="errors.get('procedencia')"
          >
            <el-input
                ref="procedencia"
                v-model="formulario2.procedencia"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.procedencia')"
            />
          </el-form-item>
        </el-col>
        <el-col
            :xl="{span: 6}"
            :lg="{span: 6}"
            :md="{span: 6}"
            :sm="{span: 12}"
            :xs="{span: 12}"
        >
          <el-form-item
              prop="moneda_ingresos"
              :label="$t('forms_flujo.form2_persona.UY.moneda_ingresos')"
              :error="errors.get('moneda_ingresos')"
          >
            <el-select
                ref="moneda_ingresos"
                v-model="formulario2.moneda_ingresos"
                clearable
                :placeholder="$t('forms_flujo.form2_persona.UY.moneda_ingresos')"
            >
              <el-option
                  v-for="item in monedas"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                  :placeholder="$t('forms_flujo.form2_persona.UY.moneda_ingresos')"
              />
            </el-select>
          </el-form-item>
        </el-col>
          <el-col
              :xl="{span: 6}"
              :lg="{span: 6}"
              :md="{span: 6}"
              :sm="{span: 12}"
              :xs="{span: 12}"
          >
              <el-form-item
                  prop="monto_ingresos"
                  :label="$t('forms_flujo.form2_persona.UY.monto_ingresos')"
                  :error="errors.get('monto_ingresos')"
              >
                  <el-input
                      ref="monto_ingresos"
                      v-model="formulario2.monto_ingresos"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form2_persona.UY.monto_ingresos')"
                  />
              </el-form-item>
          </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
              prop="concepto_ingresos"
              :label="$t('forms_flujo.form2_persona.UY.concepto_ingresos')"
              :error="errors.get('concepto_ingresos')"
          >
            <el-input
                ref="concepto_ingresos"
                v-model="formulario2.concepto_ingresos"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.concepto_ingresos')"
            />
          </el-form-item>
        </el-col>
          <el-col
                  :xl="{span: 12}"
                  :lg="{span: 12}"
                  :md="{span: 12}"
                  :sm="{span: 24}"
                  :xs="{span: 24}"
          >
              <el-form-item
                      prop="pais_opera"
                      :label="$t('forms_flujo.form2_persona.UY.pais_opera')"
                      :error="errors.get('pais_opera')"
              >
                  <el-select
                          ref="pais_opera"
                          v-model="formulario2.pais_opera"
                          clearable
                          :placeholder="$t('forms_flujo.form2_persona.UY.pais_opera')"
                  >
                      <el-option
                              v-for="item in paises"
                              :key="item.id"
                              :label="item.nombre"
                              :value="item.nombre"
                              :placeholder="$t('forms_flujo.form2_persona.UY.pais_opera')"
                      />
                  </el-select>
              </el-form-item>
          </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="destino_prestamo"
                  :label="$t('forms_flujo.form2_persona.UY.destino_prestamo')"
                  :error="errors.get('destino_prestamo')"
          >
            <el-input
                    ref="destino_prestamo"
                    v-model="formulario2.destino_prestamo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.destino_prestamo')"
            />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
          <el-col
                  :xl="{span: 12}"
                  :lg="{span: 12}"
                  :md="{span: 12}"
                  :sm="{span: 24}"
                  :xs="{span: 24}"
          >
              <el-form-item
                      prop="info_extra.aldia_obligaciones"
                      :label="$t('forms_flujo.form2_persona.UY.aldia_obligaciones')"
                      :error="errors.get('info_extra.aldia_obligaciones')"
              >
                  <el-switch
                          v-model="formulario2.info_extra.aldia_obligaciones"
                          active-text="SI"
                          inactive-text="NO"
                  />
              </el-form-item>
          </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
        <el-form-item
                prop="info_extra.beneficiario"
                :label="$t('forms_flujo.form2_persona.UY.beneficiario')"
                :error="errors.get('info_extra.beneficiario')"
        >
          <el-switch
                  v-model="formulario2.info_extra.beneficiario"
                  active-text="SI"
                  inactive-text="NO"
          />
        </el-form-item>
        </el-col>
      </el-row>
        <br/>
        <el-col
            :span="24"
            class="text-center pz-large-margin"
        >
            <div class="pz-title">
                {{ $t('forms_flujo.form2_persona.UY.otros_datos') }}
            </div>
        </el-col>

      <el-row :gutter="20">
        <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
        >
          <el-form-item
              prop="info_extra.comosecontacto"
              :label="$t('forms_flujo.form2_persona.UY.comosecontacto')"
              :error="errors.get('info_extra.comosecontacto')"
          >
            <el-input
                ref="info_extra.comosecontacto"
                v-model="formulario2.info_extra.comosecontacto"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.comosecontacto')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
              prop="info_extra.actividad_esperada"
              :label="$t('forms_flujo.form2_persona.UY.actividad_esperada')"
              :error="errors.get('info_extra.actividad_esperada')"
          >
            <el-input
                ref="info_extra.actividad_esperada"
                v-model="formulario2.info_extra.actividad_esperada"
                :type="'text'"
                :placeholder="$t('forms_flujo.form2_persona.UY.actividad_esperada')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="personal[0].nombre_completo"
                  :label="$t('forms_flujo.form2_persona.UY.personal.nombre_completo')"
                  :error="errors.get('personal[0].nombre_completo')"
          >
            <el-input
                    v-model="formulario2.personal[0].nombre_completo"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.personal.nombre_completo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="personal[0].telefono"
                  :label="$t('forms_flujo.form2_persona.UY.personal.telefono')"
                  :error="errors.get('personal[0].telefono')"
          >
            <el-input
                    v-model="formulario2.personal[0].telefono"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.personal.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="institucional[0].nombre_completo"
                  :label="$t('forms_flujo.form2_persona.UY.institucional.nombre_completo')"
                  :error="errors.get('institucional[0].nombre_completo')"
          >
            <el-input
                    v-model="formulario2.institucional[0].nombre_completo"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.institucional.nombre_completo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="institucional[0].telefono"
                  :label="$t('forms_flujo.form2_persona.UY.institucional.telefono')"
                  :error="errors.get('institucional[0].telefono')"
          >
            <el-input
                    v-model="formulario2.institucional[0].telefono"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.institucional.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import {required} from "@/commons/utils/Rules";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import {
        getCoinTypes,
        getPais
    } from "@/routes/api/resources";
    export default {
        name: 'PzPersonFormExt2Hipotecalo',
        components: {PzFormFlujo},
        mixins: [baseform2],
        data() {

            return {
                paises: [],
                monedas: [],
                isjubilado:false,
                formulario2: {
                    procedencia: '',
                    moneda_ingresos: '',
                    monto_ingresos: '',
                    concepto_ingresos: '',
                    pais_opera: '',
                    destino_prestamo: '',
                    info_extra:{
                        aldia_obligaciones: '',
                        beneficiario: '',
                        comosecontacto: '',
                        actividad_esperada: '',
                    },
                    personal:[{
                        nombre_completo:'',
                        telefono:'',
                    }],
                    institucional:[{
                        nombre_completo:'',
                        telefono:'',
                    }],
                },

                disable: true,
                rules_formulario2:
                    {

                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
                created() {
                    this.fetchData();
                },
                methods: {
                    fetchData() {
                        this.$store.dispatch('loader/up', {trigger: this.$options.name})
                        let coins = getCoinTypes().then((res) => {
                            this.monedas = res.data;
                        }).catch(() => {
                            this.monedas = [];
                        });
                        let paises = getPaises().then((res) => {
                            this.paises = res.data;
                        }).catch(() => {
                            this.paises = [];
                        });
                        return Promise.all([coins,paises]).then(() => {
                        }).finally(() => {this.$store.dispatch('loader/down', {trigger: this.$options.name})});
                    },
                },
            };
        },

    }
</script>

